<template>
  <FormItemWrapper
    type="input"
    :has-errors="hasErrors"
    :force-show-form="forceShowForm"
    :editable="editable"
    :no-wrap-text="noWrapText"
    :focus-background-color="focusBackgroundColor"
  >
    <template #text>
      <a-form-item :label="label" v-bind="errors" :required="required">
        <div class="form-item-text">{{ inputValue || emptyText }}</div>
      </a-form-item>
    </template>
    <template #input="{ toggleEditMode }">
      <a-form-model-item
        ref="formModelItem"
        :label="label"
        v-bind="errors"
        :required="required"
        :rules="customRules"
        :prop="prop"
      >
        <a-input-number
          v-model="inputValue"
          style="width: 100%"
          :auto-focus="autoFocus"
          v-bind="$attrs"
          :min="min"
          :max="max"
          v-on="$listeners"
          @pressEnter="event => event.target.blur()"
          @blur="
            $event => {
              toggleEditMode($event)
              onBlur()
            }
          "
        />
      </a-form-model-item>
    </template>
  </FormItemWrapper>
</template>
<script>
import FormItemWrapper from '@/components/common/forms/FormItemWrapper'
import { rulesMessages } from '@/constants/error-messages'
import { requiredRule } from '@/constants/validation-rules'
import HInputMixin from '@/mixins/HInputMixin'

export default {
  components: { FormItemWrapper },
  extends: HInputMixin,
  props: {
    value: {
      type: [String, Number]
    },
    min: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: false
    }
  },
  emits: ['input'],
  computed: {
    inputValue: {
      set(value) {
        this.$emit('input', value)
        this.prop && this.$refs.formModelItem?.onFieldChange()
      },
      get() {
        return this.value
      }
    },
    customRules() {
      const customRules = []

      if (this.required) {
        customRules.push(requiredRule)
      }

      if (Number.isInteger(this.min)) {
        customRules.push({
          min: this.min,
          type: 'number',
          trigger: ['change', 'blur'],
          message: rulesMessages.num.min(this.min)
        })
      }

      if (Number.isInteger(this.max)) {
        customRules.push({
          max: this.max,
          type: 'number',
          trigger: ['change', 'blur'],
          message: rulesMessages.num.max(this.max)
        })
      }

      return [...this.userRules, ...customRules]
    }
  },
  methods: {
    onBlur() {
      this.prop && this.$refs.formModelItem?.onFieldBlur()
    }
  }
}
</script>
