<template>
  <a-row type="flex" justify="space-between" class="header">
    <a-row type="flex" :gutter="gutter" style="flex: 1">
      <slot name="header" />
      <a-col
        v-for="(header, i) in headers"
        :key="i"
        class="sorter-wrapper"
        :style="colSpans[i] === undefined ? 'flex: 1' : ''"
        :span="colSpans[i]"
      >
        <div
          :class="{ sorter: header.sort }"
          role="button"
          data-cy="button"
          @click="sort(header)"
        >
          <slot
            v-if="header.customRender"
            :name="header.customRender"
            :header="header"
          />
          <div v-else style="display: flex">
            <h4 class="ant-list-item-meta-title" data-cy="item-title">
              {{ header.title }}
              <a-tooltip
                v-if="header.tooltipTitle"
                :title="header.tooltipTitle"
              >
                <a-icon :type="header.tooltipIcon" />
              </a-tooltip>
            </h4>
            <span v-if="header.sort" class="sorter-icon">
              <a-icon type="caret-up" :class="{ on: orderBy === header.key }" />
              <a-icon
                type="caret-down"
                :class="{ on: orderBy === `-${header.key}` }"
              />
            </span>
          </div>
        </div>
      </a-col>
    </a-row>
    <ul v-if="actionsCols > 0" class="ant-list-item-action" data-cy="list-item">
      <li v-for="index in actionsCols" :key="index">
        <span class="action-placeholder" />
      </li>
    </ul>
  </a-row>
</template>

<script>
export default {
  queryModels: ['ordering'],
  props: {
    headers: {
      type: Array,
      required: true
    },
    actionsCols: {
      type: Number,
      default: 0
    },
    colSpans: {
      type: Array,
      default: () => []
    },
    gutter: {
      type: Number,
      default: 24
    }
  },
  emits: ['sort'],
  data() {
    return {
      orderBy: null
    }
  },
  beforeUnmount() {
    this.ordering = undefined
  },
  methods: {
    sort(header) {
      if (!header.sort) return

      const descending = `-${header.key}`

      this.orderBy =
        header.key === this.orderBy
          ? descending
          : this.orderBy === descending
          ? null
          : header.key

      this.$emit('sort', this.orderBy)
      this.ordering = this.orderBy || undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.action-placeholder {
  display: block;
  width: 40px;
  height: 0;
}

.sorter-wrapper {
  display: flex;
}

.sorter {
  display: flex;
  cursor: pointer;

  .ant-list-item-meta-title {
    color: var(--gray-color);
  }
}

.sorter-icon {
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  cursor: pointer;
  transform: scale(0.916667);

  .anticon {
    position: relative;
    color: #bfbfbf;
    font-size: 12px;
    transition: color 0.2s;

    &:first-child {
      top: 2px;
    }

    &:last-child {
      top: -2px;
    }

    &.on {
      color: #0b0204;
    }
  }
}
</style>
