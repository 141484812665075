import GraduateIcon from '@/components/icons/GraduateIcon.vue'
import {
  getFirstDayOfSchoolWeek,
  getLastDayOfSchoolWeek
} from '@/functions/utils'
import type { Route } from '@/types/route'
import { isSummerCampsInstance } from '@/utils/feature-flags'

import { getFirstPossibleRoute } from '../utils/getFirstPossibleRoute'

const IS_SUMMER_CAMPS_INSTANCE = isSummerCampsInstance()

export const studentTabRoutes = [
  {
    path: 'mission-control',
    name: 'mission-control' as const,
    component: () =>
      import('@/views/StudentDetails/MissionControlDetails/index.vue'),
    meta: {
      icon: 'dashboard',
      title: 'Mission Control',
      permissions: ['mission_control.view_missioncontrol'],
      tab: 'students'
    },
    children: [
      {
        path: 'focus-edit',
        name: 'focus-edit' as const,
        component: () =>
          import(
            IS_SUMMER_CAMPS_INSTANCE
              ? '@/views/StudentDetails/MissionControlDetails/Focus/Edit/scFocusEdit.vue'
              : '@/views/StudentDetails/MissionControlDetails/Focus/Edit/index.vue'
          ),
        meta: {
          title: 'Edit Focus',
          permissions: ['mission_control.change_missioncontrol'],
          tab: 'students'
        }
      },
      {
        path: 'personal-edit',
        name: 'personal-edit' as const,
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Personal/Edit/index.vue'
          ),
        meta: {
          title: 'Edit Personal',
          permissions: ['mission_control.change_missioncontrol'],
          tab: 'students'
        }
      },
      {
        path: 'achievements-edit',
        name: 'achievements-edit' as const,
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Personal/Edit/AchievementList/index.vue'
          ),
        meta: {
          title: 'Edit Achievements',
          permissions: ['achievements.change_studentachievement'],
          tab: 'students'
        }
      },
      {
        path: 'internships-edit',
        name: 'internships-edit' as const,
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Personal/InternshipEditView.vue'
          ),
        meta: {
          title: 'Edit Internships',
          permissions: ['internships.change_studentinternship'],
          tab: 'students'
        }
      },
      {
        path: 'tae-projects-edit',
        name: 'tae-projects-edit' as const,
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/TAE/Edit/ProjectList/index.vue'
          ),
        meta: {
          title: 'Edit TAE Project',
          permissions: ['mission_control.change_taeproject'],
          tab: 'students'
        }
      },
      {
        path: 'tae-packages-edit',
        name: 'tae-packages-edit' as const,
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/TAE/Edit/Packages/PackagesEditTable.vue'
          ),
        meta: {
          title: 'Edit TAE Packages',
          permissions: ['mission_control.change_taesportpackageitem'],
          tab: 'students'
        }
      },
      {
        path: 'tae-experiences-edit',
        name: 'tae-experiences-edit' as const,
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/TAE/Edit/ExperienceList/index.vue'
          ),
        meta: {
          title: 'Edit TAE Project',
          permissions: ['mission_control.change_studenttaeexperience'],
          tab: 'students'
        }
      },
      {
        path: 'exams-edit',
        name: 'exams-edit' as const,
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Exams/Edit/ExamList/index.vue'
          ),
        meta: {
          title: 'Edit Exams',
          permissions: ['exams.change_studentexternalexam'],
          tab: 'students'
        }
      },
      {
        path: 'transcripts-edit',
        name: 'transcripts-edit' as const,
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Exams/Edit/TranscriptList/index.vue'
          ),
        meta: {
          title: 'Edit Transcripts',
          permissions: ['exams.change_transcript'],
          tab: 'students'
        }
      },
      {
        path: 'exam-recommendations-edit',
        name: 'exam-recommendations-edit' as const,
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Exams/Edit/Recommendations/index.vue'
          ),
        meta: {
          title: 'Edit Exam Recommendations',
          permissions: ['exams.change_exam'],
          tab: 'students'
        }
      },
      {
        path: 'recommendations-edit',
        name: 'recommendations-edit' as const,
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Recommendations/Edit/index.vue'
          ),
        meta: {
          title: 'Edit Recommendations',
          permissions: ['mission_control.change_missioncontrol'],
          tab: 'students'
        }
      }
    ]
  },
  {
    path: 'courses',
    name: 'student-details-courses' as const,
    component: () => import('@/components/common-new/course/CourseView.vue'),
    meta: {
      icon: 'book',
      title: 'Courses',
      permissions: ['grades.view_accgrade'],
      tab: 'students'
    }
  },
  {
    path: 'behaviour',
    name: 'student-behaviour' as const,
    redirect: 'behaviour/merit',
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      icon: 'line-chart',
      label: 'Behaviour',
      permissions: ['merits.view_merit'],
      tab: 'students'
    },
    children: [
      {
        path: 'merit',
        name: 'student-merit-list' as const,
        component: () =>
          import('@/views/StudentDetails/Behaviour/Merit/index.vue'),
        meta: {
          title: 'Merit',
          permissions: ['merits.view_merit'],
          tab: 'students'
        }
      },
      {
        path: 'comments',
        name: 'student-merit-comments' as const,
        component: () =>
          import('@/views/StudentDetails/Behaviour/Comments/index.vue'),
        meta: {
          title: 'Comments',
          permissions: ['permissions.view_studentcomments'],
          tab: 'students'
        }
      }
    ]
  },
  {
    path: 'attendances',
    name: 'student-attendances' as const,
    component: () =>
      import(
        '@/views/StudentDetails/AcademicAttendances/AcademicAttendances.vue'
      ),
    meta: {
      icon: 'calendar',
      title: 'Academic Attendances',
      permissions: ['permissions.view_attendance_report'],
      tab: 'students'
    },
    query: {
      lesson__date_after: getFirstDayOfSchoolWeek(),
      lesson__date_before: getLastDayOfSchoolWeek()
    }
  },
  {
    path: 'idp-plans',
    name: 'student-idp-plans' as const,
    redirect: 'idp-plans',
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      icon: 'radar-chart',
      title: 'IDP Plan',
      permissions: ['students_idp.view_studentidpplan'],
      tab: 'students'
    },
    children: [
      {
        path: '',
        name: 'idp-plan-list' as const,
        component: () =>
          import('@/views/StudentDetails/IdpPlanList/IdpPlanList.vue'),
        meta: {
          tab: 'students',
          title: 'IDP Plan list'
        }
      },
      {
        path: 'create',
        name: 'idp-create' as const,
        component: () =>
          import('@/views/StudentDetails/IdpPlanCreate/index.vue'),
        meta: {
          title: 'Create students IDP Plan',
          permissions: ['students_idp.add_studentidpplan'],
          tab: 'students'
        }
      },
      {
        path: ':id',
        name: 'idp-edit' as const,
        component: () =>
          import('@/views/StudentDetails/IdpPlanCreate/index.vue'),
        meta: {
          title: 'Edit students IDP Plan',
          permissions: ['students_idp.change_studentidpplan'],
          tab: 'students'
        }
      },
      {
        path: ':id/details',
        name: 'idp-details' as const,
        component: () =>
          import('@/views/StudentDetails/IdpPlanDetails/index.vue'),
        meta: {
          title: 'Preview IDP Plan',
          permissions: ['students_idp.view_studentidpplan'],
          tab: 'students'
        }
      }
    ]
  },
  {
    path: 'health',
    name: 'student-health-details' as const,
    component: () => import('@/views/StudentDetails/Health/StudentHealth.vue'),
    meta: {
      icon: 'medicine-box',
      title: 'Health card',
      permissions: ['allergies.view_studentallergy'],
      tab: 'students'
    }
  },
  {
    path: 'details',
    name: 'student-basic-informations' as const,
    component: () =>
      import('@/views/StudentDetails/Details/StudentBasicInformation.vue'),
    meta: {
      icon: 'user',
      label: 'Basic information',
      permissions: ['students.view_student'],
      tab: 'students',
      title: 'Student details'
    }
  }
] satisfies Route[]

const studentsRoutes = [
  {
    path: '',
    name: 'students-list' as const,
    component: () => import('@/views/StudentList/StudentList.vue'),
    meta: {
      title: 'Students',
      tab: 'students',
      permissions: ['students.view_student']
    }
  },
  {
    path: 'add',
    name: 'student-add' as const,
    component: () => import('@/views/StudentDetails/StudentForm.vue'),
    meta: {
      title: 'Student add',
      permissions: ['students.add_student'],
      tab: 'students'
    }
  },
  {
    path: ':studentId',
    name: 'student-details' as const,
    component: () => import('@/views/StudentDetails/StudentBaseLayout.vue'),
    meta: {
      tab: 'students'
    },
    props: {
      routes: studentTabRoutes
    },
    redirect: () => getFirstPossibleRoute(studentTabRoutes),
    children: studentTabRoutes
  },
  {
    path: ':studentId',
    name: 'student-edit' as const,
    component: () => import('@/views/StudentDetails/StudentForm.vue'),
    meta: {
      title: 'Student edit',
      permissions: ['students.change_student'],
      tab: 'students'
    }
  },
  {
    path: ':studentId/courses/transcript-of-grades/:transcriptId',
    name: 'student-details-courses-transcript-of-grades' as const,
    component: () =>
      import(
        '@/views/StudentDetails/TranscriptOfGrades/TranscriptOfGradesView.vue'
      ),
    meta: {
      title: 'Transcript of grades',
      tab: 'students'
    }
  }
] satisfies Route[]

export const studentsInnerRoutes = [
  {
    path: 'students',
    name: 'students-inner' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Students',
      tab: 'students',
      iconComponent: GraduateIcon
    },
    redirect: () => getFirstPossibleRoute(studentsRoutes),
    children: studentsRoutes
  }
] satisfies Route[]
